import moment from "moment";
import { post } from "./api";

export async function registerMetadata(data) {
  try {
    const lastRegister = sessionStorage.getItem("REGISTER_METADATA_DATETIME");
    if (lastRegister) {
      const diffSeconds = moment().diff(moment(lastRegister), "seconds");
      if (diffSeconds < 5) {
        return;
      }
    }
    const idMetadataMarketing = sessionStorage.getItem("idMetadataMarketing");
    data.idMetadataRef = idMetadataMarketing || "";
    const result = await post(`public/register-metadata`, data);
    sessionStorage.setItem("idMetadataMarketing", result._id);
    sessionStorage.setItem("REGISTER_METADATA_DATETIME", new Date());
  } catch (e) {
    console.log(e);
  }
}
