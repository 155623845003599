import { getRandomString } from "./utils";

export function generateDeviceId() {
  if (!localStorage.getItem("deviceIdQuitaBoletos")) {
    let deviceId = new Date().getTime();
    deviceId += "_";
    deviceId += getRandomString(80);
    localStorage.setItem("deviceIdQuitaBoletos", deviceId);
  }
}
