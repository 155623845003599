import { useRoutes } from "react-router-dom";

// project import
import BiometriaRoutes from "./BiometriaRoutes";
import AreaAbertaParceiroRoutes from "./AreaAbertaParceiroRoutes";
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import DashboardRoutes from "./DashboardRoutes";
import DashboardParceiroRoutes from "./DashboardParceiroRoutes";
import GeralRoutes from "./GeralRoutes";
import CreditoPessoalRoutes from "./CreditoPessoalRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    CreditoPessoalRoutes,
    BiometriaRoutes,
    DashboardParceiroRoutes,
    AreaAbertaRoutes,
    AreaAbertaParceiroRoutes,
    DashboardRoutes,
    GeralRoutes,
  ]);
}
